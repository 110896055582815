import type { GQLMessage } from '@AresEkb/sirius-components-core';

export interface GQLRunTransformationFlowMutationData {
  runTransformationFlow: GQLRunTransformationFlowPayload;
}

export interface GQLRunTransformationFlowPayload {
  __typename: string;
}

export interface GQLRunTransformationFlowSuccessPayload extends GQLRunTransformationFlowPayload {
  id: string;
  messages: GQLMessage[];
}

export interface GQLErrorPayload extends GQLRunTransformationFlowPayload {
  messages: GQLMessage[];
}

export function isSuccessPayload(
  payload: GQLRunTransformationFlowPayload,
): payload is GQLRunTransformationFlowSuccessPayload {
  return payload.__typename === 'RunTransformationFlowSuccessPayload';
}

export function isErrorPayload(payload: GQLRunTransformationFlowPayload): payload is GQLErrorPayload {
  return payload.__typename === 'ErrorPayload';
}
