import { CircularProgress } from '@mui/material';

import './Loader.css';

export function Loader() {
  return (
    <div className="loader">
      <CircularProgress />
    </div>
  );
}
