import { TableFooter } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../components/Loader';

import { ProjectRow } from './ProjectRow';
import { useProjectList } from './useProjectList';

export function ProjectTable() {
  const [page, setPage] = useState(0);
  const size = 20;

  const { data, loading, refreshProjects } = useProjectList(page, size);
  const projects = data?.viewer.projects.edges.map((edge) => edge.node) ?? [];
  const count = data?.viewer.projects.pageInfo.count ?? 0;

  const { t } = useTranslation('metamodel', { keyPrefix: 'project.list' });

  if (loading) {
    return <Loader />;
  }

  if (!projects.length) {
    return t('empty');
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell variant="head">{t('project')}</TableCell>
            <TableCell variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <ProjectRow key={project.id} project={project} onChange={refreshProjects} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              onPageChange={(_e, newPage) => setPage(newPage)}
              rowsPerPage={size}
              rowsPerPageOptions={[]}
              count={count}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
