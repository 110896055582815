import type { RepresentationComponentProps } from '@AresEkb/sirius-components-core';

import { ExtendedMarkdown } from './ExtendedMarkdown';
import { useTextSubscription } from './useTextSubscription';

import './TextRepresentation.css';

export function TextRepresentation({ editingContextId, representationId }: RepresentationComponentProps) {
  const { text, complete } = useTextSubscription(editingContextId, representationId);
  return (
    <div className="text-representation">
      {text !== null && !complete ? <ExtendedMarkdown value={text.content} /> : null}
      {complete ? <div>The text does not exist anymore</div> : null}
    </div>
  );
}
