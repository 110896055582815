import { useMultiToast } from '@AresEkb/sirius-components-core';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isErrorPayload, isSuccessPayload } from './useDeleteProject.types';
import type {
  GQLDeleteProjectMutationData,
  GQLDeleteProjectMutationVariables,
  UseDeleteProjectValue,
} from './useDeleteProject.types';

const deleteProjectMutation = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;

export function useDeleteProject(): UseDeleteProjectValue {
  const [performProjectDeletion, { loading, data, error }] = useMutation<
    GQLDeleteProjectMutationData,
    GQLDeleteProjectMutationVariables
  >(deleteProjectMutation);

  const { addErrorMessage } = useMultiToast();
  const { t: coreT } = useTranslation('siriusComponentsCore');

  useEffect(() => {
    if (error) {
      addErrorMessage(coreT('errors.unexpected'));
    }
    if (data) {
      const { deleteProject: payload } = data;
      if (isErrorPayload(payload)) {
        addErrorMessage(payload.message);
      }
    }
  }, [addErrorMessage, coreT, data, error]);

  function deleteProject(projectId: string) {
    void performProjectDeletion({
      variables: {
        input: {
          id: crypto.randomUUID(),
          projectId,
        },
      },
    });
  }

  return { deleteProject, loading, projectDeleted: isSuccessPayload(data?.deleteProject) };
}
