import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteProjectDialog } from '../../delete/DeleteProjectDialog';
import type { Project } from '../../types';

export interface DeleteProjectMenuItemProps {
  project: Project;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteProjectMenuItem(props: DeleteProjectMenuItemProps) {
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation('siriusWebApplication', { keyPrefix: 'project.list' });
  return (
    <>
      <MenuItem onClick={() => setShowDialog(true)}>
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary={t('delete')} />
      </MenuItem>
      {showDialog ? (
        <DeleteProjectDialog
          project={props.project}
          onSuccess={() => {
            setShowDialog(false);
            props.onSuccess();
          }}
          onCancel={() => {
            setShowDialog(false);
            props.onCancel();
          }}
        />
      ) : undefined}
    </>
  );
}
