import type { ServerContextValue } from '@AresEkb/sirius-components-core';
import { ServerContext } from '@AresEkb/sirius-components-core';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { GQLProject } from '../useProjectList.types';

import { DeleteProjectMenuItem } from './DeleteProjectMenuItem';
import { RenameProjectMenuItem } from './RenameProjectMenuItem';

export interface ProjectMenuProps {
  project: GQLProject;
  onChange: () => void;
}
export function ProjectMenu(props: ProjectMenuProps) {
  const { httpOrigin } = useContext<ServerContextValue>(ServerContext);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>();
  const { t } = useTranslation('siriusWebApplication', { keyPrefix: 'project.list' });

  function onSuccess() {
    props.onChange();
    setMenuAnchor(undefined);
  }

  function onCancel() {
    setMenuAnchor(undefined);
  }

  return (
    <>
      <Tooltip title={t('more')}>
        <IconButton aria-label="more" onClick={(e) => setMenuAnchor(e.currentTarget)} size="small">
          <MoreHorizIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {menuAnchor ? (
        <Menu id="project-actions-contextmenu" anchorEl={menuAnchor} open onClose={onCancel}>
          <RenameProjectMenuItem project={props.project} onSuccess={onSuccess} onCancel={onCancel} />
          <MenuItem
            component="a"
            href={`${httpOrigin}/api/projects/${props.project.id}`}
            type="application/octet-stream"
          >
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('download')} />
          </MenuItem>
          <DeleteProjectMenuItem project={props.project} onSuccess={onSuccess} onCancel={onCancel} />
        </Menu>
      ) : null}
    </>
  );
}
