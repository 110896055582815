import type {
  ComponentExtension,
  DataExtension,
  ExtensionRegistryMergeStrategy,
  RepresentationComponentFactory,
  RepresentationMetadata,
} from '@AresEkb/sirius-components-core';
import { ExtensionRegistry, representationFactoryExtensionPoint } from '@AresEkb/sirius-components-core';
import { DeckRepresentation } from '@AresEkb/sirius-components-deck';
import type { EdgeData, NodeData, ReactFlowPropsCustomizer } from '@AresEkb/sirius-components-diagrams';
import {
  diagramRendererReactFlowPropsCustomizerExtensionPoint,
  DiagramRepresentation,
} from '@AresEkb/sirius-components-diagrams';
import { FormDescriptionEditorRepresentation } from '@AresEkb/sirius-components-formdescriptioneditors';
import { FormRepresentation } from '@AresEkb/sirius-components-forms';
import { GanttRepresentation } from '@AresEkb/sirius-components-gantt';
import { PortalRepresentation } from '@AresEkb/sirius-components-portals';
import { TableRepresentation } from '@AresEkb/sirius-components-tables';
import { treeItemContextMenuEntryExtensionPoint, TreeRepresentation } from '@AresEkb/sirius-components-trees';
import type { ProjectSettingTabContribution } from '@AresEkb/sirius-web-application';
import {
  createProjectAreaCardExtensionPoint,
  footerExtensionPoint,
  httpOptionsConfigurersExtensionPoint,
  NavigationBar,
  navigationBarIconExtensionPoint,
  navigationBarLeftContributionExtensionPoint,
  navigationBarMenuIconExtensionPoint,
  navigationBarRightContributionExtensionPoint,
  projectSettingsTabExtensionPoint,
  routerExtensionPoint,
} from '@AresEkb/sirius-web-application';
import PeopleIcon from '@mui/icons-material/People';
import type { Edge, Node, ReactFlowProps } from '@xyflow/react';
import type { RouteProps } from 'react-router-dom';

import { DocumentTreeItemApiContextMenuContribution } from '../explorer/DocumentTreeItemApiContextMenuContribution';
import { ApplicationLogo } from '../navigationBar/ApplicationLogo';
import { ApplicationTitle } from '../navigationBar/ApplicationTitle';
import { UserMenu } from '../navigationBar/UserMenu';
import { ProjectList } from '../projects/list/ProjectList';
import { ProjectMembers } from '../projects/members/members/ProjectMembers';
import { TextRepresentation } from '../text/TextRepresentation';
import { RunTransformationFlowContextMenuContribution } from '../transformations/RunTransformationFlowContextMenuContribution';

export const extensionRegistry = new ExtensionRegistry();

extensionRegistry.putData(httpOptionsConfigurersExtensionPoint, {
  identifier: 'apolloClient#httpOptionsConfigurers',
  data: [(options) => ({ ...options, credentials: 'include' })],
});

// Navigation Bar

extensionRegistry.addComponent(navigationBarIconExtensionPoint, {
  identifier: 'navigationBar#icon',
  Component: () => <ApplicationLogo />,
});

extensionRegistry.addComponent(navigationBarLeftContributionExtensionPoint, {
  identifier: `metamodel_${navigationBarLeftContributionExtensionPoint.identifier}_title`,
  Component: () => <ApplicationTitle />,
});

extensionRegistry.addComponent(navigationBarRightContributionExtensionPoint, {
  identifier: `siriusweb_${navigationBarRightContributionExtensionPoint.identifier}_omnibox`,
  Component: () => null,
});
extensionRegistry.addComponent(navigationBarRightContributionExtensionPoint, {
  identifier: `metamodel_${navigationBarRightContributionExtensionPoint.identifier}_usermenu`,
  Component: () => <UserMenu />,
});

extensionRegistry.addComponent(navigationBarMenuIconExtensionPoint, {
  identifier: 'navigationBarMenu#icon',
  Component: () => null,
});

// Project Creation

extensionRegistry.addComponent(createProjectAreaCardExtensionPoint, {
  identifier: `siriusweb_${createProjectAreaCardExtensionPoint.identifier}_newProjectCard`,
  Component: () => null,
});

extensionRegistry.addComponent(createProjectAreaCardExtensionPoint, {
  identifier: `siriusweb_${createProjectAreaCardExtensionPoint.identifier}_showAllProjectTemplatesCard`,
  Component: () => null,
});

// Project Settings

extensionRegistry.putData(projectSettingsTabExtensionPoint, {
  identifier: `metamodel_${projectSettingsTabExtensionPoint.identifier}`,
  data: [
    {
      id: 'members',
      title: 'Members',
      icon: <PeopleIcon />,
      component: ProjectMembers,
    },
  ],
});

// Footer

extensionRegistry.addComponent(footerExtensionPoint, {
  identifier: 'footer',
  Component: () => null,
});

// Explorer

extensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `metamodel_${treeItemContextMenuEntryExtensionPoint.identifier}_document`,
  Component: DocumentTreeItemApiContextMenuContribution,
});
extensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `metamodel_${treeItemContextMenuEntryExtensionPoint.identifier}_element`,
  Component: RunTransformationFlowContextMenuContribution,
});

// Representation Kinds

function getType(representation: RepresentationMetadata): string | null {
  const query = representation.kind.substring(representation.kind.indexOf('?') + 1, representation.kind.length);
  const params = new URLSearchParams(query);
  const type = params.get('type');
  return type;
}

const representationFactories: RepresentationComponentFactory[] = [
  (representationMetadata) => (getType(representationMetadata) === 'Diagram' ? DiagramRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Form' ? FormRepresentation : null),
  (representationMetadata) =>
    getType(representationMetadata) === 'FormDescriptionEditor' ? FormDescriptionEditorRepresentation : null,
  (representationMetadata) => (getType(representationMetadata) === 'Gantt' ? GanttRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Deck' ? DeckRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Portal' ? PortalRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Tree' ? TreeRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Table' ? TableRepresentation : null),
  (representationMetadata) => (getType(representationMetadata) === 'Text' ? TextRepresentation : null),
];

extensionRegistry.putData(representationFactoryExtensionPoint, {
  identifier: `siriusweb_${representationFactoryExtensionPoint.identifier}`,
  data: representationFactories,
});

// Diagrams

function reactFlowPropsCustomizer(props: ReactFlowProps<Node<NodeData>, Edge<EdgeData>>) {
  return { ...props, proOptions: { hideAttribution: true } };
}
const diagramPanelExtension: DataExtension<ReactFlowPropsCustomizer[]> = {
  identifier: `metamodel_${diagramRendererReactFlowPropsCustomizerExtensionPoint.identifier}`,
  data: [reactFlowPropsCustomizer],
};
extensionRegistry.putData(diagramRendererReactFlowPropsCustomizerExtensionPoint, diagramPanelExtension);

const routes: RouteProps[] = [
  {
    path: '/',
    Component: () => (
      <div className="projects-view">
        <NavigationBar />
        <main>
          <ProjectList />
        </main>
      </div>
    ),
  },
  //   {
  //     path: '/acceleo',
  //     component: () => <TextEditor />,
  //   },
];

extensionRegistry.putData(routerExtensionPoint, {
  identifier: `metamodel_${routerExtensionPoint.identifier}`,
  data: routes,
});

export class MetamodelExtensionRegistryMergeStrategy implements ExtensionRegistryMergeStrategy {
  public mergeComponentExtensions(
    _identifier: string,
    existingValues: ComponentExtension<unknown>[],
    newValues: ComponentExtension<unknown>[],
  ): ComponentExtension<unknown>[] {
    const overriddenIds = new Set(newValues.map((v) => v.identifier));
    return [...existingValues.filter((v) => !overriddenIds.has(v.identifier)), ...newValues];
  }

  public mergeDataExtensions(
    identifier: string,
    existingValue: DataExtension<unknown>,
    newValue: DataExtension<unknown>,
  ): DataExtension<unknown> {
    if (identifier === projectSettingsTabExtensionPoint.identifier) {
      return {
        ...existingValue,
        data: [
          ...(existingValue.data as ProjectSettingTabContribution[]),
          ...(newValue.data as ProjectSettingTabContribution[]),
        ],
      };
    }
    return newValue;
  }
}
