export interface UseDeleteProjectValue {
  deleteProject: (projectId: string) => void;
  loading: boolean;
  projectDeleted: boolean;
}

export interface GQLDeleteProjectMutationData {
  deleteProject: GQLDeleteProjectPayload;
}

export interface GQLDeleteProjectPayload {
  __typename: string;
}

export interface GQLErrorPayload extends GQLDeleteProjectPayload {
  message: string;
}

export interface GQLDeleteProjectMutationVariables {
  input: GQLDeleteProjectMutationInput;
}

export interface GQLDeleteProjectMutationInput {
  id: string;
  projectId: string;
}

export function isSuccessPayload(payload?: GQLDeleteProjectPayload): boolean {
  return payload?.__typename === 'SuccessPayload';
}

export function isErrorPayload(payload: GQLDeleteProjectPayload): payload is GQLErrorPayload {
  return payload.__typename === 'ErrorPayload';
}
