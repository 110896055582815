import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, Link as RouterLink } from 'react-router-dom';

import useAuth from '../../app/auth';

import { ProjectTable } from './ProjectTable';
import { useCreateProjectFromTemplate } from './useCreateProjectFromTemplate';
import type { GQLCreateProjectFromTemplateSuccessPayload } from './useCreateProjectFromTemplate.types';

import './ProjectList.css';

export function ProjectList() {
  const { user } = useAuth();
  const { createProjectFromTemplate, projectCreatedFromTemplate } = useCreateProjectFromTemplate();
  const { t } = useTranslation('metamodel', { keyPrefix: 'project.list' });

  if (!user) {
    return <div className="project-list">{t('authenticationRequired')}</div>;
  }

  const redirectUrl = projectCreatedFromTemplate ? getRedirectUrl(projectCreatedFromTemplate) : undefined;
  if (redirectUrl) {
    return <Navigate to={redirectUrl} replace />;
  }

  return (
    <div className="project-list">
      <div className="project-list-actions">
        <Button
          className="new-project-button"
          variant="contained"
          startIcon={<CreateNewFolderOutlinedIcon />}
          onClick={() => createProjectFromTemplate('instance-template')}
        >
          {t('new')}
        </Button>
        <Button
          className="upload-project-button"
          variant="outlined"
          startIcon={<DriveFolderUploadOutlinedIcon />}
          component={RouterLink}
          to="/upload/project"
        >
          {t('upload')}
        </Button>
        <Button
          className="new-domain-button"
          variant="outlined"
          startIcon={<SchemaOutlinedIcon />}
          onClick={() => createProjectFromTemplate('domain-template')}
        >
          {t('domain')}
        </Button>
      </div>
      <ProjectTable />
    </div>
  );
}

function getRedirectUrl(projectCreatedFromTemplate: GQLCreateProjectFromTemplateSuccessPayload) {
  const { project, representationToOpen } = projectCreatedFromTemplate;
  return representationToOpen
    ? `/projects/${project.id}/edit/${representationToOpen.id}`
    : `/projects/${project.id}/edit`;
}
