import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link as RouterLink } from 'react-router-dom';

import { ProjectMenu } from './menu/ProjectMenu';
import type { GQLProject } from './useProjectList.types';

export interface ProjectRowProps {
  project: GQLProject;
  onChange: () => void;
}

export function ProjectRow(props: ProjectRowProps) {
  return (
    <TableRow key={props.project.id} hover>
      <TableCell>
        <Link component={RouterLink} to={`/projects/${props.project.id}/edit`}>
          {props.project.name}
        </Link>
      </TableCell>
      <TableCell align="right">
        <ProjectMenu project={props.project} onChange={props.onChange} />
      </TableCell>
    </TableRow>
  );
}
