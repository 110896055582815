/* eslint-disable i18next/no-literal-string */
import { Link } from 'react-router-dom';

import './ApplicationTitle.css';

export function ApplicationTitle() {
  return (
    <Link className="application-title" to="/">
      Metamodel
    </Link>
  );
}
