import { useMultiToast } from '@AresEkb/sirius-components-core';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isCreateProjectFromTemplateSuccessPayload, isErrorPayload } from './useCreateProjectFromTemplate.types';
import type {
  GQLCreateProjectFromTemplateMutationData,
  GQLCreateProjectFromTemplateMutationVariables,
  UseCreateProjectFromTemplateValue,
} from './useCreateProjectFromTemplate.types';

const createProjectFromTemplateMutation = gql`
  mutation createProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
    createProjectFromTemplate(input: $input) {
      __typename
      ... on CreateProjectFromTemplateSuccessPayload {
        project {
          id
        }
        representationToOpen {
          id
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;

export function useCreateProjectFromTemplate(): UseCreateProjectFromTemplateValue {
  const [performProjectCreationFromTemplate, { loading, data, error }] = useMutation<
    GQLCreateProjectFromTemplateMutationData,
    GQLCreateProjectFromTemplateMutationVariables
  >(createProjectFromTemplateMutation);

  const { addErrorMessage, addMessages } = useMultiToast();
  const { t: coreT } = useTranslation('siriusComponentsCore');

  useEffect(() => {
    if (error) {
      addErrorMessage(coreT('errors.unexpected'));
    }
    if (data) {
      const { createProjectFromTemplate: payload } = data;
      if (isErrorPayload(payload)) {
        addMessages(payload.messages);
      }
    }
  }, [addErrorMessage, addMessages, coreT, data, error]);

  function createProjectFromTemplate(templateId: string) {
    void performProjectCreationFromTemplate({
      variables: {
        input: {
          id: crypto.randomUUID(),
          templateId,
        },
      },
    });
  }

  const projectCreatedFromTemplate =
    data && isCreateProjectFromTemplateSuccessPayload(data.createProjectFromTemplate)
      ? data.createProjectFromTemplate
      : undefined;

  return { createProjectFromTemplate, loading, projectCreatedFromTemplate };
}
