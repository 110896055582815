import type { GQLMessage } from '@AresEkb/sirius-components-core';

export interface UseRenameProjectValue {
  renameProject: (projectId: string, newName: string) => void;
  loading: boolean;
  projectRenamed: boolean;
}

export interface GQLRenameProjectMutationData {
  renameProject: GQLRenameProjectPayload;
}

export interface GQLRenameProjectPayload {
  __typename: string;
}

export interface GQLErrorPayload extends GQLRenameProjectPayload {
  messages: GQLMessage[];
}

export interface GQLRenameProjectMutationVariables {
  input: GQLRenameProjectMutationInput;
}

export interface GQLRenameProjectMutationInput {
  id: string;
  projectId: string;
  newName: string;
}

export function isSuccessPayload(payload?: GQLRenameProjectPayload): boolean {
  return payload?.__typename === 'RenameProjectSuccessPayload';
}

export function isErrorPayload(payload: GQLRenameProjectPayload): payload is GQLErrorPayload {
  return payload.__typename === 'ErrorPayload';
}
