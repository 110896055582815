import { useMultiToast } from '@AresEkb/sirius-components-core';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isErrorPayload, isSuccessPayload } from './useRenameProject.types';
import type {
  GQLRenameProjectMutationData,
  GQLRenameProjectMutationVariables,
  UseRenameProjectValue,
} from './useRenameProject.types';

const renameProjectMutation = gql`
  mutation renameProject($input: RenameProjectInput!) {
    renameProject(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;

export function useRenameProject(): UseRenameProjectValue {
  const [performProjectRename, { loading, data, error }] = useMutation<
    GQLRenameProjectMutationData,
    GQLRenameProjectMutationVariables
  >(renameProjectMutation);

  const { addErrorMessage, addMessages } = useMultiToast();
  const { t: coreT } = useTranslation('siriusComponentsCore');

  useEffect(() => {
    if (error) {
      addErrorMessage(coreT('errors.unexpected'));
    }
    if (data) {
      const { renameProject: payload } = data;
      if (isErrorPayload(payload)) {
        addMessages(payload.messages);
      }
    }
  }, [addErrorMessage, addMessages, coreT, data, error]);

  function renameProject(projectId: string, newName: string) {
    void performProjectRename({
      variables: {
        input: {
          id: crypto.randomUUID(),
          projectId,
          newName,
        },
      },
    });
  }

  return { renameProject, loading, projectRenamed: isSuccessPayload(data?.renameProject) };
}
