import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Project } from '../types';

import { useRenameProject } from './useRenameProject';

export interface RenameProjectDialogProps {
  project: Project;
  onSuccess: () => void;
  onCancel: () => void;
}

export function RenameProjectDialog(props: RenameProjectDialogProps) {
  const [name, setName] = useState(props.project.name);
  const [dirty, setDirty] = useState(false);
  const { renameProject, loading, projectRenamed } = useRenameProject();
  const { t } = useTranslation('metamodel', { keyPrefix: 'project.rename' });

  useEffect(() => {
    if (projectRenamed) {
      props.onSuccess();
    }
  }, [projectRenamed, props]);

  return (
    <Dialog open onClose={props.onCancel} aria-labelledby="dialog-title" maxWidth="xs" fullWidth>
      <DialogTitle id="dialog-title">{t('title')}</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setDirty(true);
          }}
          label={t('name.label')}
          placeholder={t('name.placeholder')}
          helperText={t('name.helperText')}
          error={dirty && (name.trim().length === 0 || name.trim().length > 1024)}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onCancel}>
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={() => renameProject(props.project.id, name)} disabled={loading}>
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
