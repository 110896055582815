import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Project } from '../types';

import { useDeleteProject } from './useDeleteProject';

export interface DeleteProjectDialogProps {
  project: Project;
  onSuccess: () => void;
  onCancel: () => void;
}

export function DeleteProjectDialog(props: DeleteProjectDialogProps) {
  const { deleteProject, loading, projectDeleted } = useDeleteProject();
  const { t } = useTranslation('metamodel', { keyPrefix: 'project.delete' });

  useEffect(() => {
    if (projectDeleted) {
      props.onSuccess();
    }
  }, [projectDeleted, props]);

  return (
    <Dialog open onClose={props.onCancel} aria-labelledby="dialog-title" maxWidth="xs" fullWidth>
      <DialogTitle id="dialog-title">{t('title', { name: props.project.name })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onCancel}>
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={() => deleteProject(props.project.id)} disabled={loading}>
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
