import type { GQLMessage } from '@AresEkb/sirius-components-core';

export interface UseCreateProjectFromTemplateValue {
  createProjectFromTemplate: (templateId: string) => void;
  loading: boolean;
  projectCreatedFromTemplate?: GQLCreateProjectFromTemplateSuccessPayload;
}

export interface GQLCreateProjectFromTemplateMutationData {
  createProjectFromTemplate: GQLCreateProjectFromTemplatePayload;
}

export interface GQLCreateProjectFromTemplatePayload {
  __typename: string;
}

export interface GQLCreateProjectFromTemplateSuccessPayload extends GQLCreateProjectFromTemplatePayload {
  project: GQLProject;
  representationToOpen?: GQLRepresentationMetadata;
}

export interface GQLProject {
  id: string;
}

export interface GQLRepresentationMetadata {
  id: string;
}

export interface GQLErrorPayload extends GQLCreateProjectFromTemplatePayload {
  messages: GQLMessage[];
}

export interface GQLCreateProjectFromTemplateMutationVariables {
  input: GQLCreateProjectFromTemplateInput;
}

export interface GQLCreateProjectFromTemplateInput {
  id: string;
  templateId: string;
}

export function isCreateProjectFromTemplateSuccessPayload(
  payload: GQLCreateProjectFromTemplatePayload,
): payload is GQLCreateProjectFromTemplateSuccessPayload {
  return payload.__typename === 'CreateProjectFromTemplateSuccessPayload';
}

export function isErrorPayload(payload: GQLCreateProjectFromTemplatePayload): payload is GQLErrorPayload {
  return payload.__typename === 'ErrorPayload';
}
