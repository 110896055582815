import Markdown from 'marked-react';
import mermaid from 'mermaid';
import { useEffect } from 'react';

import { hash } from '../utils/hash';

export interface ExtendedMarkdownProps {
  value: string;
}

const renderer = {
  code(snippet: string, language: string) {
    // Change rendered element key if it's a mermaid code block and if it's content changed
    // So it's re-rendered and data-processed attribute is removed
    const key = language === 'mermaid' ? hash(snippet) : (this as unknown as { elementId: number }).elementId;
    return (
      <code key={key} className={`language-${language}`}>
        {snippet}
      </code>
    );
  },
};

export function ExtendedMarkdown(props: ExtendedMarkdownProps) {
  useEffect(() => void mermaid.run({ querySelector: '.language-mermaid' }));
  return <Markdown value={props.value} renderer={renderer} />;
}
