import { useMultiToast } from '@AresEkb/sirius-components-core';
import type { TreeItemContextMenuComponentProps } from '@AresEkb/sirius-components-trees';
import { gql, useMutation } from '@apollo/client';
import TransformIcon from '@mui/icons-material/Transform';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Fragment } from 'react';

import { isErrorPayload, isSuccessPayload } from './RunTransformationFlow.types';
import type { GQLRunTransformationFlowMutationData } from './RunTransformationFlow.types';

const runTransformationFlowMutation = gql`
  mutation runTransformationFlow($input: RunTransformationFlowInput!) {
    runTransformationFlow(input: $input) {
      __typename
      ... on RunTransformationFlowSuccessPayload {
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;

export function RunTransformationFlowContextMenuContribution(props: TreeItemContextMenuComponentProps) {
  const [runTransformationFlow] = useMutation<GQLRunTransformationFlowMutationData>(runTransformationFlowMutation);
  const { addMessages } = useMultiToast();

  if (
    !props.treeId.startsWith('explorer://') ||
    props.item.kind !== 'siriusComponents://semantic?domain=transformationinstance&entity=TransformationFlowInstance'
  ) {
    return null;
  }

  async function run() {
    const input = {
      id: crypto.randomUUID(),
      editingContextId: props.editingContextId,
      transformationFlowInstanceId: props.item.id,
    };
    props.onClose();
    const { data } = await runTransformationFlow({ variables: { input } });
    if (data) {
      const { runTransformationFlow: payload } = data;
      if (isSuccessPayload(payload) || isErrorPayload(payload)) {
        const { messages } = payload;
        addMessages(messages);
      }
    }
  }

  return (
    <Fragment key="run-transformation-context-menu-contribution">
      <MenuItem aria-disabled>
        <ListItemIcon>
          <TransformIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Run" onClick={run} />
      </MenuItem>
    </Fragment>
  );
}
