import { useMultiToast } from '@AresEkb/sirius-components-core';
import type { OnDataOptions } from '@apollo/client';
import { gql, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';

import type {
  GQLTextEventData,
  GQLTextEventInput,
  GQLTextEventPayload,
  GQLTextEventVariables,
  GQLTextRefreshedEventPayload,
  UseTextSubscriptionState,
  UseTextSubscriptionValue,
} from './useTextSubscription.types';

export const getTextEventSubscription = `
  subscription textEvent($input: TextEventInput!) {
    textEvent(input: $input) {
      __typename
      ... on TextRefreshedEventPayload {
        text {
          id
          content
        }
      }
    }
  }
  `;

function isTextRefreshedEventPayload(payload: GQLTextEventPayload): payload is GQLTextRefreshedEventPayload {
  return payload.__typename === 'TextRefreshedEventPayload';
}

export function useTextSubscription(editingContextId: string, representationId: string): UseTextSubscriptionValue {
  const [state, setState] = useState<UseTextSubscriptionState>({
    id: crypto.randomUUID(),
    text: null,
    complete: false,
  });

  const input: GQLTextEventInput = {
    id: state.id,
    editingContextId,
    representationId,
  };

  const variables: GQLTextEventVariables = { input };

  function onData({ data }: OnDataOptions<GQLTextEventData>) {
    const { data: gqlTextData } = data;
    if (gqlTextData) {
      const { textEvent: payload } = gqlTextData;
      if (isTextRefreshedEventPayload(payload)) {
        const { text } = payload;
        setState((prevState) => ({ ...prevState, text }));
      }
    }
  }

  function onComplete() {
    setState((prevState) => ({ ...prevState, complete: true }));
  }

  const { error, loading } = useSubscription<GQLTextEventData, GQLTextEventVariables>(gql(getTextEventSubscription), {
    variables,
    fetchPolicy: 'no-cache',
    onData,
    onComplete,
  });

  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage('An unexpected error has occurred, please refresh the page');
    }
  }, [addErrorMessage, error]);

  return {
    loading,
    text: state.text,
    complete: state.complete,
  };
}
