import { useMultiToast } from '@AresEkb/sirius-components-core';
import type { ApolloError, ServerError } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import type { GQLGetProjectsQueryData, GQLGetProjectsQueryVariables, ProjectList } from './useProjectList.types';

const getProjectsQuery = gql`
  query getProjects($page: Int!, $limit: Int!) {
    viewer {
      projects(page: $page, limit: $limit) {
        edges {
          node {
            ... on Project {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          count
          __typename
        }
        __typename
      }
    }
  }
`;

function isServerError(error: ApolloError['networkError']): error is ServerError {
  return error !== null && 'statusCode' in error;
}

export function useProjectList(page: number, limit: number): ProjectList {
  const variables: GQLGetProjectsQueryVariables = { page, limit };
  const { data, loading, error, refetch } = useQuery<GQLGetProjectsQueryData, GQLGetProjectsQueryVariables>(
    getProjectsQuery,
    { variables },
  );

  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error && isServerError(error.networkError) && error.networkError.statusCode === 403) {
      return;
    }
    if (error) {
      addErrorMessage(error.message);
    }
  }, [addErrorMessage, error]);

  function refreshProjects() {
    void refetch(variables);
  }

  return { data, loading, refreshProjects };
}
