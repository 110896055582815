import { ruRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

import './reset.css';
import './variables.css';
import './explorer.css';
import './form.css';
import './diagram.css';
import './app.css';

export const baseTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#043392',
      dark: '#19306f',
      light: '#CB4793',
    },
    secondary: {
      main: '#19306f',
      dark: '#1A153D',
      light: '#514B79',
    },
    text: {
      primary: '#19306f',
      disabled: '#B3BFC5',
    },
    error: {
      main: '#DE1000',
      dark: '#9B0B00',
      light: '#E43F33',
    },
    success: {
      main: '#43A047',
      dark: '#327836',
      light: '#4EBA54',
    },
    warning: {
      main: '#FF9800',
      dark: '#D98200',
      light: '#FFB800',
    },
    info: {
      main: '#2196F3',
      dark: '#1D7DCC',
      light: '#24A7FF',
    },
    divider: '#B3BFC5',
    navigation: {
      leftBackground: '#04339280',
      rightBackground: '#19306f80',
    },
    navigationBar: {
      border: '#043392',
      background: '#19306f',
    },
    selected: '#043392',
    action: {
      hover: '#04339226',
      selected: '#04339242',
    },
  },
});

function container() {
  return document.fullscreenElement ?? document.body;
}

export const applicationTheme = createTheme(
  {
    components: {
      MuiInputBase: {
        defaultProps: {
          // Prevents generation of duplicate style tags
          disableInjectingGlobalStyles: true,
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: baseTheme.palette.primary.main,
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          container,
        },
      },
      MuiTooltip: {
        defaultProps: {
          PopperProps: {
            container,
          },
        },
        styleOverrides: {
          tooltip: {
            backgroundColor: baseTheme.palette.common.black,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
    },
  },
  baseTheme,
  ruRU,
);
